<template>
  <div class="E3DPoster">
    <div v-if="token">
        <div v-loading="loading"></div>
        <div class="shadee flex0">
          <div class="alert-popUp">
              <img :src="img" alt="" class="poster_img">
              <div class="bottom flex6">
                <div class="poster" @click="poster">生成海报</div>
                <div class="goindex" @click="GoLive">返回学习</div>
              </div>
          </div>
        </div>
        <div class="shade flex0 shade1" v-show="isPoster">
            <div class="alert-popUp">
              <div class="picture">
                <img :src="img" alt="" class="poster_img">
                <div class="show_close" @click="close">
                  <i class="el-icon-circle-close cur"></i>
                </div>
              </div>
            <div class="save fx11">
                长按保存图片
            </div>
          </div>
        </div>
    </div>
    <toolTipBox :Show="postShow" :Content="postContent" :Type="postType" @postChange="postChanges" v-else></toolTipBox>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { GetE3DPoster } from '@/api/InnovationContest.js'
import toolTipBox from "./../../../Live/toolTipBox.vue"
import { dataState } from "@/api/all.js"

export default {
  name: 'E3DPoster',
  data() {
    return {
      postShow:false,
      postContent:'请登录后再来邀请好友!',
      postType:2,
      loading: true,
      LoginAlert: false,
      img:'',
      isPoster: false
    }
  },
  created() {
    if(this.$route.query.u) {
      this.$store.commit('e3dinviterId', this.$route.query.u)
    }
  },
  mounted() {
    if(!this.token) {
      // this.$toast('当前处于未登录状态，麻烦先去登录吧！');
      // setTimeout(()=>{
      //   this.$router.push('/Login?redirectUrl=' + this.$route.fullPath + '&from=' + this.$route.query.from)
      // }, 500)
      this.postShow=true
      return false
    } else if (this.clientWidth > 768) {
      this.$router.push('/Home/index?isinvitate=true');
      return false
    }
    this.GetInit()

     // 数据统计
    const data ={
      "visitType": 1,
      "gid": sessionStorage.getItem('gid') ? sessionStorage.getItem('gid') : '',
      "url": window.location.href,
      "pageUrl": "E3DPoster",
      "relationId": ""
    }
    dataState(data).then(res => {
      sessionStorage.setItem('gid',res.data);
    })
  },
  computed: {
    ...mapState(['clientWidth','token'])
  },
  methods: {
    poster(){
      this.isPoster=true
    },
    close(){
      this.isPoster=false
    },
    GoLive(){
      this.$router.push('/Home/E3DVideo')
    },
    GetInit(){
      this.loading = true;
      GetE3DPoster().then(res=>{
        if(res.status === 1){
          this.img=res.data
          this.loading = false
        }
      })
    },
    postChanges(val){
      this.postShow=val
    },
  },
  components: {
    toolTipBox,
  },
}
</script>

<style lang="scss">
 $mobil_base: 750;

  @function vw($true) {
    @return ($true / $mobil_base) * 100vw;
  }
@media (max-width: 750px) {
  .E3DPoster {
    .poster_img{width: vw(600);}
    .picture{position: relative; top:vw(-28)}
    .show_close{position: absolute; top:vw(-20);right: vw(-20);}
    .el-icon-circle-close{font-size: vw(48);
    background: #E4E4E4;
    border-radius: 50%;}
    .shadee{background: url(~@/assets/PC/InnovationContest/bg.png) no-repeat; background-size: 100%;width: 100%;
    min-height: 100vh;
    position: fixed;
    top: 0;
    left: 0;}
    .shade1{width: 100%;
    min-height: 100vh;
    position: fixed;
    top: 0;
    left: 0;background: rgba(0, 0, 0, 0.6);}
    .save{margin-top: vw(40); color: rgba(238, 231, 231, 0.6);;}
    .bottom{margin-top: vw(46);}
    .poster{width: vw(254);height: vw(76);background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), linear-gradient(90deg, #CD9DED -2.71%, #3C1152 100%);
border-radius: 22px; color: #fff; text-align: center; line-height: vw(76); font-weight: bold;
}
.goindex{width: vw(254);height: vw(76);background: #FA961A;
border-radius: 22px; color: #fff; text-align: center; line-height: vw(76);font-weight: bold;}
  }
}
</style>